import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from 'react-router-dom'

import { TransactionsInterfacePaged } from "../../../../types/transactions.type";
import { BalanceHistoryInterfacePaged } from "../../../../types/balance_history.type";
import { CardMachineInterface } from "../../../../types/card_machine.type";
import { ClientInterface } from "../../../../types/client.type";

import { cardMachinesActions } from "../../../../actions_copy/card_machine.actions";
import { clientActions } from "../../../../actions_copy/client.actions";

import PageHeader from "../../../../components/Layout/PageHeader";
import TransactionsTable from "../../../../components/TransactionsTable";
import BalanceHistoryTable from "../../../../components/BalanceHistoryTable";
import CardMachineTable from "../../../../components/CardMachineTable";
import TaxGroupModal from "../../../../components/TaxGroupModal";

import { Button, Tabs } from 'antd';
const { TabPane } = Tabs;

import './styles.css';
import InputText from "../../../../components/Inputs/Text";
import {ReactComponent as SearchIcon} from '../../../../static/icon/search.svg';
import { CloseOutlined } from '@ant-design/icons';
import AddCardMachineModal from "../../../../components/AddCardMachineModal";
import { CompaniesInterface } from "../../../../types/companies.type";
import { companiesActions } from "../../../../actions/companies";
import UsersTable from "../../../../components/UsersTable";

interface RootState {
  card_machines: {
    machines: CardMachineInterface[];
  };
  transactions: {
    card: TransactionsInterfacePaged
  };
  clients: ClientInterface[];
  balance_history:{
    balance_history: BalanceHistoryInterfacePaged;
  }; 
  companyDetails: CompaniesInterface;
}

const mapState = (state: RootState) => ({
  transactions: state.transactions.card,
  balance_history: state.balance_history.balance_history,
  card_machines: state.card_machines.machines,
  clients: state.clients
});

const actionCreators = {
  loadTransactions: companiesActions.loadTransactions,
  laodBalanceHistory: companiesActions.laodBalanceHistory,
  loadCardMachines: cardMachinesActions.loadCardMachinesByUser,
  loadClients: clientActions.loadClients,
  getCompanyDetails: companiesActions.getCompanyDetails,
};
const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ClientInformation = ({ 
  transactions, 
  card_machines,
  balance_history,
  loadTransactions,
  laodBalanceHistory,
  loadCardMachines,
  getCompanyDetails
}: PropsFromRedux) => {

  const navigate = useNavigate()
  const { id } = useParams();
  const [company, setCompany] = useState<CompaniesInterface>()
  const companyId = Number(id)

  const [filterCardMachine, setFilterCardMachine] = useState<string>("")
  const [openTaxGroupModal, setTaxGroupModal] = useState<boolean>(false)
  const [openAddCardMachineModal, setAddCardMachineModal] = useState<boolean>(false)

  const getTransactions = (page: number) => {
    loadTransactions(companyId, page);
  }

  const getBalanceHistory = (page: number) => {
    laodBalanceHistory(companyId, page);
  }
  
  useEffect(() => {  
    (async () => {
      const companyDetails = await getCompanyDetails(companyId)
      getTransactions(1)
      getBalanceHistory(1)
      setCompany(companyDetails)
      loadCardMachines(companyId)
    })()
  }, [id]);

  const removeAccent = (str:string) => {
    str = str.replace(/[àáâãäå]/g, "a")
    str = str.replace(/[èéêë]/g, "e")
    str = str.replace(/[ìíîï]/g, "i")
    str = str.replace(/[òóôõö]/g, "o")
    str = str.replace(/[ùúûü]/g, "u")
    str = str.replace(/ç/g, "c")
    return str
  }

  const handleFilterCardMachine = () => {
    let aux = card_machines
    if (filterCardMachine !== "") {
      aux = aux.filter(item => {
        const serial_number = removeAccent(item.serial_number).toLowerCase()
        const card_machine = removeAccent(filterCardMachine).toLowerCase()
        return serial_number.search(card_machine.toLowerCase()) >= 0
      })
    }

    return aux
  }
  
  return (
    <>
      <TaxGroupModal 
        company_id={Number(id)}
        tax_group_id={company?.tax_group_id}
        open={openTaxGroupModal}
        onClose={() => setTaxGroupModal(false)}
      />
      <AddCardMachineModal 
        open={openAddCardMachineModal}
        company_id={Number(id)}
        onClose={() => setAddCardMachineModal(false)}
      />
      <PageHeader title="Informações do Cliente" goTo="/clientes"/>
      <div className="client-information-cards-container">
        <div className="client-information-card-item">
          <span className="client-information-card-item-title">{company?.name}</span>
          <div style={{display: "flex", flexDirection: "column"}}>
            <span className="client-information-card-item-label">{`${company?.street}, ${company?.number}, ${company?.complement ? company.complement +', ' : ''}${company?.district}`}</span>
            <span className="client-information-card-item-label">CNPJ: {company?.cnpj}</span>
          </div>
        </div>
        <div className="client-information-card-item">
          <span className="client-information-card-item-title">Saldo</span>
          <span className="client-information-card-item-label">{Number(company?.balance).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</span>
        </div>
        <div className="client-information-card-item">
          <span className="client-information-card-item-title">Vendas no mês</span>
          <span className="client-information-card-item-label">{Number(13232).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</span>
        </div>
        <div className="client-information-card-item tax-group-card" onClick={() => setTaxGroupModal(true)}>
          <span className="client-information-card-item-title">Grupo de taxa</span>
          <span className="client-information-card-item-label">{company?.tax_group_name}</span>
        </div>
      </div>
      <Tabs className='page-header-tab' defaultActiveKey="card_machine">
        <TabPane tab="Máquinas de cartão" key="card_machine">
          <div className="clients-filter-content">
            <InputText 
              kind="primary"
              placeholder="Pesquisar por número de série..."
              suffix={filterCardMachine === "" ? <SearchIcon /> : (
                  <Button 
                      shape="circle" 
                      size="large" 
                      icon={<CloseOutlined />}
                      className="button-input"
                      onClick={() => setFilterCardMachine("")}
                  />
              )}
              value={filterCardMachine}
              onChange={(event) => setFilterCardMachine(event.target.value)}
            />
            <Button className="clients-button" onClick={() => setAddCardMachineModal(true)}>Adicionar maquina</Button>
          </div>
          <CardMachineTable is_admin card_machines={handleFilterCardMachine()} user_id={Number(id)}/>
        </TabPane>
        <TabPane tab="Transações" key="transactions">
          <TransactionsTable transactions={transactions.data} pagination={transactions.pagination} changePage={getTransactions}/>
        </TabPane>
        <TabPane tab="Histórico de saldo" key="balance_history">
        {balance_history &&<BalanceHistoryTable balance_history={balance_history.data} pagination={balance_history.pagination} changePage={getBalanceHistory}/>}
        </TabPane>
        <TabPane tab="Usuários" key="users">
          <Button className="clients-button" onClick={() => navigate(`/perfil/adicional/novo?company=${company && company.id}`)}>Adicionar usuário</Button>
          {company?.users ? (
            <UsersTable users={company?.users} company_id={company.id}/>
          ) : (
            "Empresa ainda não tem um usuário cadastrado"
          )}
        </TabPane>
      </Tabs>
    </>
  )
}

export default connector(ClientInformation);