import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import InputText from "../../../../components/Inputs/Text";
import PageHeader from "../../../../components/Layout/PageHeader";
import Button from "../../../../components/Button";

import './styles.css'
import { ViaCepService } from "../../../../services/via.cep.service";
import { userActions } from "../../../../actions/user";
import { companiesActions } from "../../../../actions/companies";
import { message } from "antd";

interface FormsProps {
    company_name: string;
    company_identity: string;
    company_zip_code: string;
    company_district: string;
    company_city: string;
    company_state: string;
    company_street: string;
    company_street_number: string;
    company_street_complement: string;
    user_master_name: string;
    user_master_identity: string;
    user_master_telephone: string;
    user_master_email: string;
    bank_name: string;
    bank_account: string;
    bank_agency: string;
    bank_holder_name: string;
    bank_holder_identity: string;
}

interface FormsErrorProps {
    company_name: { error: boolean; message: string; };
    company_identity: { error: boolean; message: string; };
    company_zip_code: { error: boolean; message: string; };
    company_district: { error: boolean; message: string; };
    company_city: { error: boolean; message: string; };
    company_state: { error: boolean; message: string; };
    company_street: { error: boolean; message: string; };
    company_street_number: { error: boolean; message: string; };
    company_street_complement: { error: boolean; message: string; };
    user_master_name: { error: boolean; message: string; };
    user_master_identity: { error: boolean; message: string; };
    user_master_telephone: { error: boolean; message: string; };
    user_master_email: { error: boolean; message: string; };
    bank_name: { error: boolean; message: string; };
    bank_account: { error: boolean; message: string; };
    bank_agency: { error: boolean; message: string; };
    bank_holder_name: { error: boolean; message: string; };
    bank_holder_identity: { error: boolean; message: string; };
}

const ClientsForms = () => {

    const navigate = useNavigate()

    const regex = {
        cpf: /(\d{3})(\d{3})(\d{3})(\d{2})/,
        cnpj: /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        zip_code: /(\d{2})(\d{3})(\d{3})/,
        telephone: /(\d{2})(\d{4})(\d{4})/,
        cellphone: /(\d{2})(\d{5})(\d{4})/
    }
    const [forms, setForms] = useState<FormsProps>({
        company_name: "",
        company_identity: "",
        company_zip_code: "",
        company_district: "",
        company_city: "",
        company_state: "",
        company_street: "",
        company_street_number: "",
        company_street_complement: "",
        user_master_name: "",
        user_master_identity: "",
        user_master_telephone: "",
        user_master_email: "",
        bank_name: "",
        bank_account: "",
        bank_agency: "",
        bank_holder_name: "",
        bank_holder_identity: "",
    })
    const [formsError, setError] = useState<FormsErrorProps>({
        company_name: { error: false, message: ""},
        company_identity: { error: false, message: ""},
        company_zip_code: { error: false, message: ""},
        company_district: { error: false, message: ""},
        company_city: { error: false, message: ""},
        company_state: { error: false, message: ""},
        company_street: { error: false, message: ""},
        company_street_number: { error: false, message: ""},
        company_street_complement: { error: false, message: ""},
        user_master_name: { error: false, message: ""},
        user_master_identity: { error: false, message: ""},
        user_master_telephone: { error: false, message: ""},
        user_master_email: { error: false, message: ""},
        bank_name: { error: false, message: ""},
        bank_account: { error: false, message: ""},
        bank_agency: { error: false, message: ""},
        bank_holder_name: { error: false, message: ""},
        bank_holder_identity: { error: false, message: ""},
    })
    
    const [acitveAddresInput, setActiveAddressInput] = useState(false)
    const [userMasterFomrsActive, setUserMasterFormsActive] = useState<boolean>(false)

    const handleForms = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setError({ ...formsError, [name]: {error: false, message: ""} })
        setForms({ ...forms, [name]: value });
    };

    const handleGetAddress = async () => {
        setForms({
            ...forms,
            company_street: "Procurando endereço...",
            company_district: "Procurando endereço...",
            company_city: "Procurando endereço...",
            company_state: "Procurando endereço...",
        })


        const zip_code = forms.company_zip_code.replace(/[^\d]/ig, '')
        const { status, data } = await ViaCepService.getAddress(zip_code)

        if (status === 200) {
            if (data.erro) {
                setForms({
                    ...forms,
                    company_street: "",
                    company_district: "",
                    company_city: "",
                    company_state: "",
                })
            } else {
                setForms({
                    ...forms,
                    company_street: data.logradouro,
                    company_district: data.bairro,
                    company_city: data.localidade,
                    company_state: data.uf,
                    company_zip_code: zip_code.replace(regex.zip_code, "$1.$2-$3")
                })
            }
            setActiveAddressInput(true)
        }
    }

    const formatIdentity = (key:keyof FormsProps) => {
        const identity = forms[key].replace(/[^\d]/ig, '')

        if(regex.cnpj.test(identity)) {
            setForms({...forms, [key]: identity.replace(regex.cnpj, "$1.$2.$3/$4-$5")})
        } else if (regex.cpf.test(identity)) {
            setForms({...forms, [key]: identity.replace(regex.cpf, "$1.$2.$3-$4")})
        }
    }

    const formatTelephone = (key:keyof FormsProps) => {
        const telephone = forms[key].replace(/[^\d]/ig, '')

        if (regex.cellphone.test(telephone)) {
            setForms({...forms, [key]: telephone.replace(regex.cellphone, "($1) $2-$3")})
        } else if(regex.telephone.test(telephone)) {
            setForms({...forms, [key]: telephone.replace(regex.telephone, "($1) $2-$3")})
        }
    }

    const validateCompanyData = () => {
        const identity = forms.company_identity.replace(/[^\d]/ig, '')

        console.log(identity)
        console.log(regex.cnpj)
        console.log(regex.cnpj.test(identity))

        if (forms.company_name === "") {
            setError({
                ...formsError,
                company_name: {error: true, message: "Nome da empresa não pode ser nulo."}
            })
            return false
        } else if (forms.company_identity === "") {
            setError({
                ...formsError,
                company_identity: {error: true, message: "CNPJ da empresa não pode ser nulo."}
            })
            return false
        } else if (identity.length === 14 && !regex.cnpj.test(identity)) {
            setError({
                ...formsError,
                company_identity: {error: true, message: "CNPJ inválido."}
            })
            return false
        } else if (identity.length === 11 && !regex.cpf.test(identity)) {
            setError({
                ...formsError,
                company_identity: {error: true, message: "CPF inválido."}
            })
            return false
        } else if (forms.company_zip_code === "") {
            setError({
                ...formsError,
                company_zip_code: {error: true, message: "CEP da empresa não pode ser nulo."}
            })
            return false
        } else if (!regex.zip_code.test(forms.company_zip_code.replace(/[^\d]/ig, ''))) {
            setError({
                ...formsError,
                company_zip_code: {error: true, message: "CEP da empresa inválido."}
            })
            return false
        } else if (forms.company_street_number === "") {
            setError({
                ...formsError,
                company_street_number: {error: true, message: "Número da empresa não pode ser nulo."}
            })
            return false
        }
        return true
    }

    const validateUserMaster = () => {
        const identity = forms.user_master_identity.replace(/[^\d]/ig, '')

        console.log(identity)
        console.log(regex.cpf)
        console.log(regex.cpf.test(identity))

        if (forms.user_master_name === "") {
            setError({
                ...formsError,
                user_master_name: {error: true, message: "Nome do responsável não pode ser nulo."}
            })
            return false
        } else if (forms.user_master_identity === "") {
            setError({
                ...formsError,
                user_master_identity: {error: true, message: "CPF do responsável não pode ser nulo."}
            })
            return false
        } else if (!regex.cpf.test(identity)) {
            setError({
                ...formsError,
                user_master_identity: {error: true, message: "CPF do responsável inválido."}
            })
            return false
        } else if (forms.user_master_telephone === "") {
            setError({
                ...formsError,
                user_master_telephone: {error: true, message: "Telefone do responsável não pode ser nulo."}
            })
            return false
        } else if (forms.user_master_email === "") {
            setError({
                ...formsError,
                user_master_email: {error: true, message: "Telefone do responsável não pode ser nulo."}
            })
            return false
        }
        return true
    }

    const handleSearchUserMaster = async () => {
        const user_master_email = forms.user_master_email

        if (user_master_email === "") {
            setError({
                ...formsError,
                user_master_email: {error: true, message: "E-mail do responsável não pode ser nulo."}
            })
        } else {
            const resposne = await userActions.getManagerByEmail(user_master_email)

            if (resposne){
                setUserMasterFormsActive(false)
                setForms({
                    ...forms,
                    user_master_email: resposne.email ? resposne.email : '',
                    user_master_name: resposne.name ? resposne.name : '',
                    user_master_identity: resposne.cpf ? resposne.cpf : '',
                    user_master_telephone: resposne.telephone ? resposne.telephone : '',
                })
            } else {
                setUserMasterFormsActive(true)
            }   
        }
    }

    const handleRemovingWhitespaces = (key:keyof FormsProps) => {
        const value = forms[key].trim()
        setForms({...forms, [key]: value})
    }

    const submit = async () => {
        if (validateCompanyData() && validateUserMaster()) {
            const payload = {
                company:{
                    cnpj: forms.company_identity,
                    name: forms.company_name,
                    cep: forms.company_zip_code,
                    city: forms.company_city,
                    state: forms.company_state,
                    district: forms.company_district,
                    street: forms.company_street,
                    number: forms.company_street_number,
                    complement: forms.company_street_complement,
                    telephone: "",
                    cellphone: ""
                },
                user:{
                    cpf: forms.user_master_identity,
                    name: forms.user_master_name,
                    email: forms.user_master_email,
                    telephone: forms.user_master_telephone,
                    cellphone: forms.user_master_telephone,
                    address: ""
                },
                bankinformation: {
                    name: forms.bank_holder_name,
                    document: forms.bank_holder_identity,
                    bank_name: forms.bank_name,
                    agency: forms.bank_agency,
                    account: forms.bank_account,
                    observation: "",
                    savings_account: false
                }
            }

            const response = await companiesActions.createCompany(payload)
            if(response.success) createAlert("Conta criada com sucesso", true)
            else createAlert("Falha ao criar nova conta", false)
        }
    }

    const createAlert = (messageAlert: string, success: boolean) => {
        if (success) message.success(messageAlert)
        else message.error(messageAlert)
        
        setTimeout(() => navigate(-1), 3000)
    }

    return (
        <>
            <PageHeader title="Adicionar Empresa" goTo="/clientes" />
            <div className="clients-forms-container">
                <div className="clients-forms-item">
                    <div className="clients-forms-item-paragraph-container">
                        <div className="clients-forms-item-paragraph-content">
                            <span className="clients-forms-item-paragraph-text">1</span>
                        </div>
                    </div>
                    <div className="clients-forms-item-container">
                        <span className="clients-forms-item-title">Dados da Empresa</span>
                        <div className="clients-forms-item-content">
                            <div>
                                <span>Nome da Empresa</span>
                                <InputText 
                                    kind="primary"
                                    placeholder="Digite o nome da empresa"
                                    name="company_name"
                                    value={forms.company_name}
                                    onChange={(event) => handleForms(event)}
                                    onBlur={() => handleRemovingWhitespaces("company_name")}
                                    validate={formsError.company_name.error ? "error" : ""}
                                    errorMessage={formsError.company_name.message}
                                />
                            </div>
                            <div>
                                <span>CPF/CNPJ</span>
                                <InputText 
                                    kind="primary"
                                    placeholder="Digite apenas números"
                                    name="company_identity"
                                    value={forms.company_identity}
                                    onChange={(event) => handleForms(event)}
                                    onBlur={() => formatIdentity("company_identity")}
                                    validate={formsError.company_identity.error ? "error" : ""}
                                    errorMessage={formsError.company_identity.message}
                                />
                            </div>
                            <div>
                                <span>CEP</span>
                                <InputText 
                                    kind="primary"
                                    placeholder="Digite apenas números"
                                    name="company_zip_code"
                                    value={forms.company_zip_code}
                                    onChange={(event) => handleForms(event)}
                                    onBlur={handleGetAddress}
                                    validate={formsError.company_zip_code.error ? "error" : ""}
                                    errorMessage={formsError.company_zip_code.message}
                                />
                            </div>
                            <div>
                                <span>Bairro</span>
                                <InputText 
                                    disabled={!acitveAddresInput}
                                    kind="primary"
                                    name="company_district"
                                    value={forms.company_district}
                                    onChange={(event) => handleForms(event)}
                                />
                            </div>
                            <div>
                                <span>Rua</span>
                                <InputText 
                                    disabled={!acitveAddresInput}
                                    kind="primary"
                                    name="company_street"
                                    value={forms.company_street}
                                    onChange={(event) => handleForms(event)}
                                />
                            </div>
                            <div className="clients-forms-item-input-group">
                                <div>
                                    <span>Número</span>
                                    <InputText 
                                        kind="primary"
                                        placeholder="Número da rua"
                                        name="company_street_number"
                                        value={forms.company_street_number}
                                        onChange={(event) => handleForms(event)}
                                        validate={formsError.company_street_number.error ? "error" : ""}
                                        errorMessage={formsError.company_street_number.message}
                                    />
                                </div>
                                <div>
                                    <span>Complemento</span>
                                    <InputText 
                                        kind="primary"
                                        placeholder="Opcional"
                                        name="company_street_complement"
                                        value={forms.company_street_complement}
                                        onChange={(event) => handleForms(event)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clients-forms-item">
                    <div className="clients-forms-item-paragraph-container">
                        <div className="clients-forms-item-paragraph-content">
                            <span className="clients-forms-item-paragraph-text">2</span>
                        </div>
                    </div>
                    <div className="clients-forms-item-container">
                        <span className="clients-forms-item-title">Dados do Usuário Responsável</span>
                        <div className="clients-forms-item-content">
                            <div>
                                <span>E-mail do responsável</span>
                                <InputText 
                                    kind="primary"
                                    placeholder="Digite o e-mail do responsável"
                                    name="user_master_email"
                                    value={forms.user_master_email}
                                    onBlur={handleSearchUserMaster}
                                    onChange={(event) => handleForms(event)}
                                    validate={formsError.user_master_email.error ? "error" : ""}
                                    errorMessage={formsError.user_master_email.message}
                                />
                            </div>
                            <div>
                                <span>Nome do responsável</span>
                                <InputText 
                                    disabled={!userMasterFomrsActive}
                                    kind="primary"
                                    placeholder="Digite o nome completo"
                                    name="user_master_name"
                                    value={forms.user_master_name}
                                    onChange={(event) => handleForms(event)}
                                    validate={formsError.user_master_name.error ? "error" : ""}
                                    errorMessage={formsError.user_master_name.message}
                                />
                            </div>
                            <div>
                                <span>CPF do responsável</span>
                                <InputText 
                                    disabled={!userMasterFomrsActive}
                                    kind="primary"
                                    placeholder="Digite apenas número"
                                    name="user_master_identity"
                                    value={forms.user_master_identity}
                                    onChange={(event) => handleForms(event)}
                                    onBlur={() => formatIdentity("user_master_identity")}
                                    validate={formsError.user_master_identity.error ? "error" : ""}
                                    errorMessage={formsError.user_master_identity.message}
                                />
                            </div>
                            <div>
                                <span>Telefone do responsável</span>
                                <InputText 
                                    disabled={!userMasterFomrsActive} 
                                    kind="primary"
                                    placeholder="Digite apenas número"
                                    name="user_master_telephone"
                                    value={forms.user_master_telephone}
                                    onChange={(event) => handleForms(event)}
                                    onBlur={() => formatTelephone("user_master_telephone")}
                                    validate={formsError.user_master_telephone.error ? "error" : ""}
                                    errorMessage={formsError.user_master_telephone.message}
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="clients-forms-item">
                    <div className="clients-forms-item-paragraph-container">
                        <div className="clients-forms-item-paragraph-content">
                            <span className="clients-forms-item-paragraph-text">3</span>
                        </div>
                    </div>
                    <div className="clients-forms-item-container">
                        <span className="clients-forms-item-title">Dados Bancários</span>
                        <div className="clients-forms-item-content">
                            <div>
                                <span>Banco</span>
                                <InputText 
                                    kind="primary"
                                    placeholder="Digite o nome do banco"
                                    name="bank_name"
                                    value={forms.bank_name}
                                    onChange={(event) => handleForms(event)}
                                    validate={formsError.bank_name.error ? "error" : ""}
                                    errorMessage={formsError.bank_name.message}
                                />
                            </div>
                            <div>
                                <span>Agência</span>
                                <InputText 
                                    kind="primary"
                                    placeholder="Digite apenas números"
                                    name="bank_agency"
                                    value={forms.bank_agency}
                                    onChange={(event) => handleForms(event)}
                                    validate={formsError.bank_agency.error ? "error" : ""}
                                    errorMessage={formsError.bank_agency.message}
                                />
                            </div>
                            <div>
                                <span>Conta</span>
                                <InputText 
                                    kind="primary"
                                    placeholder="Digite o número da conta incluindo o digito"
                                    name="bank_account"
                                    value={forms.bank_account}
                                    onChange={(event) => handleForms(event)}
                                    validate={formsError.bank_account.error ? "error" : ""}
                                    errorMessage={formsError.bank_account.message}
                                />
                            </div>
                            <div>
                                <span>Nome do titular</span>
                                <InputText 
                                    kind="primary"
                                    placeholder="Digite o nome completo"
                                    name="bank_holder_name"
                                    value={forms.bank_holder_name}
                                    onChange={(event) => handleForms(event)}
                                    validate={formsError.bank_holder_name.error ? "error" : ""}
                                    errorMessage={formsError.bank_holder_name.message}
                                />
                            </div>
                            <div>
                                <span>CPF/CNPJ do titular</span>
                                <InputText 
                                    kind="primary"
                                    placeholder="Digite apenas número"
                                    name="bank_holder_identity"
                                    value={forms.bank_holder_identity}
                                    onChange={(event) => handleForms(event)}
                                    onBlur={() => formatIdentity("bank_holder_identity")}
                                    validate={formsError.bank_holder_identity.error ? "error" : ""}
                                    errorMessage={formsError.bank_holder_identity.message}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clients-forms-button-content">
                    <Button 
                        label="ADICIONAR EMPRESA"
                        onClick={submit}
                    />
                </div>
            </div>
        </>
    )
}

export default ClientsForms;