import { useEffect } from 'react';
import { Tabs } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import { TransactionsInterfacePaged } from '../../../types/transactions.type';
import { companiesActions } from '../../../actions/companies';
import { UserInterface } from '../../../types/user.type';

import PageHeader from '../../../components/Layout/PageHeader';
import TransactionsTable from '../../../components/TransactionsTable';

import './styles.css';
import { BalanceHistoryInterface } from '../../../types/balance_history.type';
import BalanceHistoryTable from '../../../components/BalanceHistoryTable';

interface RootState {
  transactions: {
    card: TransactionsInterfacePaged,
    pix: BalanceHistoryInterface[]
  };
  authentication: {
    user: UserInterface;
  }
}

const mapState = (state: RootState) => ({
  pix_deposit: state.transactions.pix,
  transactions: state.transactions.card,
  user: state.authentication.user
});

const actionCreators = {
  loadPixDepositHist: companiesActions.loadPixDeposit,
  loadTransactions: companiesActions.loadTransactions,
};
const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

const { TabPane } = Tabs;

const TransactionsPage = ({ 
  user, 
  pix_deposit,
  transactions, 
  loadTransactions,
  loadPixDepositHist,
}: PropsFromRedux) => {

  const getTransactions = (page: number) => {
    if(user && user.companies && user.companies.length > 0) {
      const company_id = user.companies[0].id
      loadTransactions(company_id, page);
    }
  }

  useEffect(() => {
    if (user.role === "admin") {
      console.log("admin")
    } else if(user && user.companies && user.companies.length > 0) {
      const company_id = user.companies[0].id
      getTransactions(1);
      loadPixDepositHist(company_id)
    }
  }, []);

  return (
    <>
      <PageHeader title="Relatório de vendas" />
      <Tabs>
        <TabPane tab="Cartão" key="open">
          <div className='transactions-table-content'>
            <TransactionsTable transactions={transactions.data} pagination={transactions.pagination} changePage={getTransactions}/>
          </div>
        </TabPane>
        <TabPane tab="Pix" key="pix">
          <div className='transactions-table-content'>
            <BalanceHistoryTable balance_history={pix_deposit}/>
          </div>
        </TabPane>
      </Tabs>      
    </>
  );
};

export default connector(TransactionsPage);
