import { ConnectedProps, connect } from "react-redux";

import { companiesActions } from "../../../actions/companies";
import PageHeader from "../../../components/Layout/PageHeader";

import { BalanceHistoryInterfacePaged } from "../../../types/balance_history.type";
import { UserInterface } from "../../../types/user.type";
import { useEffect, useState } from "react";
import PaymentVoucherTable from "../../../components/PaymentsVoucherTable";

import './styles.css'

interface RootState {
    balance_history: {
        certificates: BalanceHistoryInterfacePaged;
    };
    
    authentication: {
        user: UserInterface;
    }
}

const mapState = (state: RootState) => ({
    certificates: state.balance_history.certificates,
    user: state.authentication.user,
});

const actionCreators = {
    //laodBalanceHistory: companiesActions.laodBalanceHistory,
    listCertificatesPaged: companiesActions.listCertificatesPaged
};

const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

const PaymentVoucher = ({ 
    user,
    certificates, 
    listCertificatesPaged 
}: PropsFromRedux) => {

    const storage = localStorage.getItem("userDetails")
    const userDetails:UserInterface = storage && JSON.parse(storage)
    const operationsName = userDetails.limits?.map(item => item.operation_type_name)
    const operationsId = userDetails.limits?.map(item => item.operation_type_id)
    const [filterOperation, setFilterOperation] = useState<string>("")

    const getBalanceHistory = (page: number) => {
        if(user && user.companies && user.companies.length > 0) {
            const company_id = user.companies[0].id
            listCertificatesPaged(company_id, page);
        }
    }

    useEffect(() => {
        if (user.role === "admin") {
            console.log("opa")
        } else if(user && user.companies && user.companies.length > 0) {
            getBalanceHistory(1);
        }
    }, []);

    const handleFilterPayementVoucher = () => {
        if (certificates.data) {
            let paymentVoucher = certificates.data//.filter(item => item.withdrawal_id > 0)
    
            if (filterOperation !== "") paymentVoucher = paymentVoucher.filter(item => item.operation_type.toLocaleLowerCase().includes(filterOperation))
    
            if (user.role !== "admin") {
                paymentVoucher = paymentVoucher.filter(item => operationsName?.includes(item.operation_type))
            }
    
            return paymentVoucher
        }
    }

    return (
        <>
            <PageHeader title="Comprovantes"/>
            <div className="voucher-container">
                <div className="voucher-filter-content"> 
                    <div className="voucher-filter-content-button">
                        {operationsId?.includes(6) &&
                            <button className={`voucher-filter-button ${filterOperation === "boleto" && 'voucher-filter-button-active'}`} onClick={() => setFilterOperation("boleto")}>Boleto</button>
                        }
                        {operationsId?.includes(8) &&
                            <button className={`voucher-filter-button ${filterOperation === "pix" && 'voucher-filter-button-active'}`} onClick={() => setFilterOperation("pix")}>PIX</button>
                        }
                    </div>
                </div>
                <div>
                    {certificates &&
                        <PaymentVoucherTable 
                            balance_history={handleFilterPayementVoucher()}
                            pagination={certificates.pagination}
                            changePage={getBalanceHistory}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default connector(PaymentVoucher);