import React from 'react';
import { Layout, Image, Menu } from 'antd';
import { useNavigate } from 'react-router-dom'
import type { MenuProps } from 'antd';

import Logo from '../../../static/tamborete.png';
import LogoSmall from '../../../static/logo2.png';

import {ReactComponent as HomeIcon} from '../../../static/icon/home.svg';
import {ReactComponent as ProfileIcon} from '../../../static/icon/profile.svg';
import {ReactComponent as LogoutIcon} from '../../../static/icon/logout.svg';
import {ReactComponent as PixIcon} from '../../../static/icon/pix.svg';
import {ReactComponent as BalanceIcon} from '../../../static/icon/balance.svg';
import {ReactComponent as SalesIcon} from '../../../static/icon/sales.svg';
import {ReactComponent as PaymentsIcon} from '../../../static/icon/payments.svg';
import {ReactComponent as ClientsIcon} from '../../../static/icon/clients.svg';
import {ReactComponent as SimulatorIcon} from '../../../static/icon/simulator.svg';
import {ReactComponent as VoccherIcon} from '../../../static/icon/voucher.svg';
import {ReactComponent as TaxGroupsIcon} from '../../../static/icon/tax-groups.svg';

import "./styles.css"
import { UserInterface } from '../../../types/user.type';
import { connect, ConnectedProps } from 'react-redux';

interface RootState {
    user: {
        info: UserInterface;
    };
}

const mapState = (state: RootState) => ({
    user: state.user.info,
});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

const { Sider } = Layout;

interface SidebarProps {
    logout: () => void;
}

const Sidebar = ({ user, logout }: SidebarProps & PropsFromRedux) => {
    const { innerWidth: width, location } = window;
    const navigate = useNavigate()

    type MenuItem = Required<MenuProps>['items'][number];
    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        onClick: () => void,
        icon?: React.ReactNode
    ):MenuItem => {
        return {
            key,
            icon,
            label,
            onClick
        }
    }

    const handleMyPrfileRedirect = () => {
        if (user.permissions?.control_limits) return "/perfil"
        return "/perfil/me"
    }

    const items: MenuItem[] = [
        getItem("Página inicial", "1", () => navigate('/'), <HomeIcon />),
        getItem("Meus dados", "2", () => navigate(handleMyPrfileRedirect()), <ProfileIcon />),
        getItem("Pix", "3", () => navigate('/pix'), <PixIcon />),
        //getItem("Transferências", "4", () => navigate('/transferencias'), <TransactionsIcon />),
        getItem("Pagamentos", "5", () => navigate('/pagamentos'), <PaymentsIcon />),
        getItem("Histórico de Saldo", "6", () => navigate('/saldo'), <BalanceIcon />),
        getItem("Relatório de Vendas", "7", () => navigate('/vendas'), <SalesIcon />),
        //getItem("Máquinas de Cartão", "8", () => navigate('/maquininhas'), <CardMachineIcon />),
        getItem("Simulação de Taxas", "9", () => navigate('/simulacao'), <SimulatorIcon />),
        getItem("Clientes", "10", () => navigate('/clientes'), <ClientsIcon />),
        getItem("Comprovantes", "11", () => navigate('/comprovantes'), <VoccherIcon />),
        getItem("Grupo de taxas", "12", () => navigate('/grupo-taxas'), <TaxGroupsIcon />),
        getItem("Sair", "13", () => logout(), <LogoutIcon />)
    ]

    const handleActiveMenu = () => {
        if (location.pathname === '/') {
            return "1"
        } else if (location.pathname === "/perfil") {
            return "2"
        }
        return ""
    }

    const handleItems = () => {
        if (user.role !== "admin") {
            let permissions = items.filter(item => item?.key !== "10")
            permissions = permissions.filter(item => item?.key !== "12")

            if (!user.permissions?.view_balance) permissions = permissions.filter(item => item?.key !== "6") //ver saldo
            if (!user.permissions?.view_transactions) permissions = permissions.filter(item => item?.key !== "7") // ver vendas

            if (user.limits && user.limits.length > 0) {
                if(user.limits.filter(item => item.operation_type_id === 8).length === 0) permissions = permissions.filter(item => item?.key !== "3") // ver pix
                if(user.limits.filter(item => item.operation_type_id === 6).length === 0) permissions = permissions.filter(item => item?.key !== "5") // ver pagamentos
            } else {
                permissions = permissions.filter(item => item?.key !== "3") // ver pix
                permissions = permissions.filter(item => item?.key !== "5") // ver pagamentos
                permissions = permissions.filter(item => item?.key !== "11") // ver comprovantes
            }

            return permissions
        }
        return items.filter(item => !["6", "9", "11", "7"].includes(String(item?.key)))
    }
    
    return (
        <Sider 
            className="sidebar" 
            breakpoint="lg" 
            collapsedWidth="65"
        >
            <Image preview={false} src={width <= 991 ? LogoSmall : Logo} width={width <= 991 ? "45px" : "160px"}/>
            <Menu
                activeKey={handleActiveMenu()}
                mode="inline" 
                items={handleItems()} 
            />
        </Sider>
    )
}

export default connector(Sidebar);

