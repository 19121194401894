import React, { useState, useEffect } from 'react'
import { Modal, Button, message } from 'antd';

import { WithdrawInterface } from '../../types/withdraw.type';

import Logo from '../../static/tamborete-pay-modal.png';

import './styles.css'
import { withdrawActions } from '../../actions/withdrawal';
import { connect, ConnectedProps } from 'react-redux';
import InputText from '../Inputs/Text';

interface PaymentsModalProps {
    open: boolean;
    type: string;
    typeOfWithdraw: string;
    withdraw?: WithdrawInterface;
    onClose: () => void;
}

const mapState = () => ({})

const actionCreators = {
    concludeWithdraw: withdrawActions.concludeWithdraw,
    refuseWithdraw: withdrawActions.refuseWithdraw,
    concludeWithdrawWithoutPayment: withdrawActions.concludeWithdrawWithoutPayment
}
interface ConfirmationPix {
    observationRequest: string;
}


const connector = connect(mapState, actionCreators)

type PropsFromRedux = ConnectedProps<typeof connector> & PaymentsModalProps;

const PaymentsModal = ({
    open,
    type,
    typeOfWithdraw,
    withdraw,
    onClose,
    concludeWithdraw,
    concludeWithdrawWithoutPayment,
    refuseWithdraw
}:PropsFromRedux) => {
    const [forms, setForms] = useState<ConfirmationPix>({
        observationRequest: "",
      })
    const [loadingActive, setLoadingActive] = useState(false)
    const handleForms = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        //setError({ ...formsError, [name]: {error: false, message: ""} })
        setForms({ ...forms, [name]: value });
    };
    const handleApproveActionPayments = async () => {
        setLoadingActive(true)
        try {
            if(!withdraw?.id) return
            switch(type) {
                case 'approve':
                    await concludeWithdraw(withdraw.id, typeOfWithdraw,forms.observationRequest)
                    break;
                case 'reprove':
                    await refuseWithdraw(withdraw.id)
                    break;
                case 'conclude':
                    await concludeWithdrawWithoutPayment(withdraw.id)
            }
            window.location.reload()
        } catch (error) {
            setLoadingActive(false)
            message.error(`Falha ao ${type === 'approve' ? "aprovar" : "rescusar"} saque`);
            onClose()
        }
    }

    const ModalTitle = () => {
        return (
            <div className='payments-modal-title-container'>
                <img src={Logo} alt="" />
            </div>
        )
    }

    const ModalFooter = () => {
        return (
            <div className='payments-modal-footer-content'>
                <Button className='payments-modal-button-reprove' onClick={onClose}>Não</Button>
                <Button className='payments-modal-button-approve' onClick={handleApproveActionPayments} loading={loadingActive}>Sim</Button>
            </div>
        )
    }

    let titleVerb = "";
    switch(type) {
        case 'approve':
            titleVerb = "APROVAR"
            break;
        case 'reprove':
            titleVerb = "REPROVAR"
            break;
        case 'conclude':
            titleVerb = "CONCLUIR"
            break;
    }

    return (
        <Modal 
            className="payments-modal"
            title={<ModalTitle />}
            footer={<ModalFooter />}
            onCancel={onClose}
            width={400}
            open={open}
        >
            <h2 className='payments-modal-body-title'>{`${titleVerb} RETIRADA`}</h2>
            <span className='payments-modal-body-subtitle'>Tem certeza que deseja {titleVerb} a retirada?</span>
            <div className='payments-modal-body-describe'>
                <div className='payments-modal-body-describe-item'>
                    <span className='payments-modal-body-describe-item-title'>Dados do cliente</span>
                    <span>
                        <span>Cliente: </span>
                        {withdraw?.company_name}
                    </span>
                    <span>
                        <span>Valor: </span>
                        {Number(withdraw?.value).toLocaleString("pt-br", {style:"currency", currency: "BRL"})}
                    </span>
                </div>
                <div className='payments-modal-body-describe-item'>
                    <span className='payments-modal-body-describe-item-title'>Dados de pagamento</span>
                    <span>
                        <span>{`Código ${typeOfWithdraw === "PIX" ? "PIX" : "Boleto"}: `}</span>
                        {typeOfWithdraw === "PIX" ? withdraw?.pix_code : withdraw?.ticket_code}
                    </span>
                </div>             
            </div>
            <div className='payments-modal-body-describe-item payments-modal-body-describe-input'>
                <div>
                    <span>Observação</span>
                        <InputText 
                            kind="secondary"
                            placeholder="Digite uma observação"
                            name="observationRequest"
                            value={forms.observationRequest}
                            onChange={handleForms}
                        />
                </div>
            </div>
        </Modal>
    )
}
export default connector(PaymentsModal);