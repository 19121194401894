import {useState} from 'react'

import { taxService } from '../../../services/tax.service';
import { TaxgroupsProps } from '../../../types/tax_group.type';

import PageHeader from "../../../components/Layout/PageHeader";
import CustomButton from '../../../components/Button';
import InputText from "../../../components/Inputs/Text";
import InputNumber from "../../../components/Inputs/Number";

import './styles.css'

interface FormsProps {
    name?: string;
    taxGroups?: TaxgroupsProps[]
}

const TaxGroup = () => {
    const installments = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

    const [forms, setForms] = useState<FormsProps>({
        name: '',
        taxGroups: []
    })

    const handleSetTaxValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        
        const taxGroups = forms?.taxGroups
        const isIntoTaxGroups = taxGroups?.filter(item => item.installments === Number(name))

        if (taxGroups && taxGroups?.length > 0 && isIntoTaxGroups && isIntoTaxGroups?.length > 0) {
            const newTaxGroup = taxGroups.map(item => {
                if (item.installments === Number(name)) {
                    item.value = Number(value)
                }

                return item
            })

            setForms({...forms, taxGroups: newTaxGroup})
        } else {
            taxGroups?.push({
                installments: Number(name),
                brand_id: 1,
                value: Number(value),
                priority: 1
            })

            setForms({...forms, taxGroups: taxGroups})
        }
    };

    const handleSetName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setForms({...forms, name: value})
    }

    const handleDisableButton = () => {
        if (forms.name !== "" && forms.taxGroups?.length === 13) {
            return false
        }
        return true
    }

    const submit = async() => {
        if (forms.name && forms.name !== "") {
            const response = await taxService.createTaxGroup({name: forms.name})
            if (response && forms.taxGroups) {
                const response = await taxService.insertTaxList(3, forms.taxGroups)
                console.log(response)
            }
        }
    }

    return (
        <>
            <PageHeader title="Grupo de taxas"/>
            <div className="tax-group-container">
                <div className="tax-group-content-name">
                    <InputText
                        placeholder='Digite o nome do grupo de taxa'
                        kind="primary"
                        onChange={handleSetName}
                    />
                </div>
                <div className="tax-group-content-installment">
                    {installments.map((item, index) => {
                        const prefix = item === 0 ? "débito": `${item}x`

                        return (
                            <div key={index}>
                                <InputNumber
                                    kind="primary"
                                    prefix={prefix}
                                    suffix={"%"}
                                    name={String(item)}
                                    onChange={handleSetTaxValue}
                                />
                            </div>
                        )
                    })}
                </div>
                <div className="tax-group-content-button">
                    <CustomButton
                        disabled={handleDisableButton()}
                        label='CADASTRAR NOVO GRUPO DE TAXA'
                        onClick={submit}
                    />
                </div>
            </div>
        </>
    )
}

export default TaxGroup;