import API from '../factory/api';
import { BalanceHistoryInterface, BalanceHistoryInterfacePaged } from '../types/balance_history.type';

import { CompaniesInterface, CreateCompaniesInterface, MonthsHeadersInterface, MonthsTotalInterface } from '../types/companies.type';
import { TransactionsInterface, TransactionsInterfacePaged } from '../types/transactions.type';
import { UserInterface, UserLimitsInterface, UserPermissionsInterface } from '../types/user.type';

const listCompanies = async () => {
    try {
        const { data } = await API().post('/company/list')
        return data as CompaniesInterface[]
    } catch (error) {
        console.log("listCompanies", error)
        throw error;
    }
}

const getCompanyDetails = async (companyId: number) => {
    try {
        const { data } = await API().post(`/company/${companyId}`)
        return data as CompaniesInterface
    } catch (error) {
        throw error
    }
}

const createCompany = async (company: CreateCompaniesInterface) => {
    try {
        const { data } = await API().post(`/company/insert_with_user`, company)
        return data
    } catch (error) {
        throw error
    }
}

const createUser = async (company_id: number, user: UserInterface) => {
    try {
        const { data } = await API().post(`/company/${company_id}/create_user`, user)
        return data
    } catch (error) {
        throw error
    }
}

const associateUser = async (company_id: number, user: {user_id?: number, limits?: UserLimitsInterface[], permissions?: UserPermissionsInterface}) => {
    try {
        const { data } = await API().post(`/company/${company_id}/associate_user_limits`, user)
        return data
    } catch (error) {
        throw error
    }
}

const checkUser = async (payload: {email: string, company_id: number}) => {
    try {
        const { data } = await API().post(`/user/check`, payload)
        return data as UserInterface
    } catch (error) {
        return false
    }
}

const disassociateUser = async (company_id: number, user:{user_id:number}) => {
    try {
        const { data } = await API().post(`/company/${company_id}/disassociate_user`, user)
        return data
    } catch (error) {
        throw error
    }
}

const getTransactions = async (company_id?: number): Promise<TransactionsInterface[]> => {
    try {
      if (company_id) {
            const { data } = await API().post(`/company/${company_id}/transactions`, {});
            return data as TransactionsInterface[];
      } else {
            const { data } = await API().post(`/company/transactions`, {});
            return data as TransactionsInterface[];
      }
    } catch (error) {
        throw error;
    }
};

const getTransactionsPaged = async (company_id?: number, page?: number): Promise<TransactionsInterfacePaged> => {
    try {
        const { data } = await API().post(`/company/${company_id}/transactions_paged`, {page: page, per_page: 10});
        return data as TransactionsInterfacePaged;
    } catch (error) {
        throw error;
    }
};

const getUserBalanceHistory = async (company_id?: number): Promise<BalanceHistoryInterface[]> => {
    try {
        const { data } = await API().post(`/balancehistory/list${company_id != undefined ? '/' + company_id : ''}`, {});
        return data as BalanceHistoryInterface[];
    } catch (error) {
        throw error;
    }
};

const getUserBalanceHistoryPaged = async (company_id?: number, page?: number): Promise<BalanceHistoryInterfacePaged> => {
    try {
        const { data } = await API().post(`/balancehistory/list_paged/${company_id}`, {page: page, per_page: 10});
        return data as BalanceHistoryInterfacePaged;
    } catch (error) {
        throw error;
    }
};

const listCertificatesPaged = async (company_id?: number, page?: number): Promise<BalanceHistoryInterfacePaged> => {
    try {
        const { data } = await API().post(`/balancehistory/list_certificates_paged/${company_id}`, {page: page, per_page: 10});
        return data as BalanceHistoryInterfacePaged;
    } catch (error) {
        throw error;
    }
};

const getPixDeposit = async (company_id?: number): Promise<BalanceHistoryInterface[]> => {
    try {
        const { data } = await API().post(`/balancehistory/listpix${company_id != undefined ? '/' + company_id : ''}`, {});
        return data as BalanceHistoryInterface[];
    } catch (error) {
        throw error;
    }
};

const loadMonthTotals = async (company_id?: number) => {
    try {
        const { data } = await API().post(`/balancehistory/month_totals/${company_id}`);
        return data as MonthsTotalInterface[];
    } catch (error) {
        throw error;
    }
};

const loadMonthHeaders =async (company_id?:number) => {
    try {
        const { data } = await API().post(`/balancehistory/headers/${company_id}`)
        return data as MonthsHeadersInterface;
    } catch (error) {
        throw error;
    }
}

const exportBalancehistory = async (company_id: number) => {
    const config = {
       timeOut:100000
    }
    try {
        const { data } = await API().post(`/balancehistory/export/${company_id}`,config, {
            headers:
            {
                'Content-Disposition': "attachment; filename=" + company_id + ".xlsx",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer',
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const CompaniesService = {
    checkUser,
    createUser,
    listCompanies,
    createCompany,
    getPixDeposit,
    associateUser,
    loadMonthTotals,
    getTransactions,
    loadMonthHeaders,
    disassociateUser,
    getCompanyDetails,
    getTransactionsPaged,
    listCertificatesPaged,
    getUserBalanceHistory,
    getUserBalanceHistoryPaged,
    exportBalancehistory
}