import { combineReducers } from 'redux';
import { userConstants } from '../constants';
import { alert } from './alert.reducer';
import authentication from './authentication.reducer';
import balance_history from './balance_history.reducer';
import bank_accounts from './bank_account.reducer';
import card_machines from './card_machine.reducer';
import clients from './client.reducer';
import companies from './companies.reducer';
import tax from './tax.reducer';
import transactions from './transactions.reducer';
import user from './user.reducer';
import withdraw from './withdraw.reducer';

const appReducer = combineReducers({
  alert,
  authentication,
  card_machines,
  clients,
  balance_history,
  bank_accounts,
  tax,
  transactions,
  withdraw,
  user,
  companies
});

const rootReducer = (state: any, action: any) => {
  if (action.type === userConstants.LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
