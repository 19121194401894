import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { formatDateYMD } from "../../helpers/utils";
import { ReceiptWithdrawRequestInterface, WithdrawInterface } from "../../types/withdraw.type";

import { Button, message } from "antd";

import InputText from "../Inputs/Text";
import InputDate from "../Inputs/Date";

import OpenedEye from '../../static/opened-eye-primary.png'
import ClosedEye from '../../static/closed-eye-primary.png'
import {ReactComponent as SearchIcon} from '../../static/icon/search.svg'

import './styles.css'
import { withdrawService } from "../../services/withdraw.service";
import { BS2ReceiptInterface } from "../../types/receipt.type";
import CustomButton from "../Button";
import { withdrawActions } from "../../actions/withdrawal";
import { UserInterface } from "../../types/user.type";
import { companiesActions } from "../../actions/companies";

interface WithdrawReceiptFormInterface extends WithdrawInterface, ReceiptWithdrawRequestInterface {
    discount: string;
    fee: string;
    other_fee: string;
}

interface RootState {
    authentication: {
        user: UserInterface;
    };
}

const mapState = (state: RootState) => ({
    user: state.authentication.user,
});

const actionCreators = {
    getCompanyDetails: companiesActions.getCompanyDetails,
    createClientWithdraw: withdrawActions.createClientWithdraw,
}

const connector = connect(mapState, actionCreators);

type PropsPixRedux = ConnectedProps<typeof connector>;

const BankSlipPayments = ({
    user,
    getCompanyDetails,
    createClientWithdraw
}:PropsPixRedux) => {

    const [visibleBalance, setVisibleBalance] = useState<boolean>(false)
    const [balance, setBalance] = useState<number>(0)
    const [visibleBankSlipContent, setVisibleBankSlipContent] = useState<boolean>(false)
    const [messageBankSlipContent, setMessageBankSlipContent] = useState<string>("")
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const [forms, setForms] = useState<WithdrawReceiptFormInterface>({
        bankinformation_id: -1,
        bank_information_name: '',
        ticket_code: '',
        is_completed: false,
        observation_response: '',
        observation_request: '',
        company_id: -1,
        company_name: '',
        balance: 0,
        value: "",
        discount: "",
        fee: "",
        other_fee: "",
        ticket_schedule_date: ""
    })

    useEffect(() => {
        const getData = async () => {
            if (user.companies) {
                const company_id = user.companies[0].id
                const response = await getCompanyDetails(company_id)
                setBalance(response.balance)
            }
        }
        getData()
    }, [user])

    const handleForms = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setForms({ ...forms, [name]: value });
    };

    const setReceiptInfo = (data: BS2ReceiptInterface) => {
        setForms({
          ...forms,
          value: String(data.valores.cobrado) || "0",
          discount: String(data.valores.descontos + data.valores.outrasDeducoes) || "0",
          fee: String(data.valores.mora) || "0",
          other_fee: String(data.valores.outrosAcrescimos) || "0",
          beneficiary_name: data.beneficiario.nome,
          beneficiary_document: data.beneficiario?.documento,
          bank_name: data.banco.nome,
          bank_code: data.banco.codigo,
          assignor_name: data.cedente,
          description: data.descricaoPagamento,
          ticket_settle_date: data.dataVencimento?.split('T', 1)[0],
        });
    };

    const loadBankSlipInfo = async () => {
        if (forms.ticket_code !== '') {
            setVisibleBankSlipContent(true)
            setMessageBankSlipContent("Carregando informações do boleto...")
            const receipt_id = forms.ticket_code?.replace(/[^\d]/ig, '') as string;
            try {
                const data = await withdrawService.getBS2ReceiptInfo(receipt_id);
                if (typeof data !== 'undefined') {
                    setMessageBankSlipContent("result")
                    setReceiptInfo(data);
                } else {
                    setMessageBankSlipContent('Não foi possível carregar as informações do boleto');
                }
            } catch {
                setMessageBankSlipContent('Não foi possível carregar as informações do boleto.');
            }
        }
    };

    const handleDate = (date: moment.Moment | null) => {
        if (date) {
            setForms({ ...forms, ticket_schedule_date: date.format("YYYY-MM-DD") })
        }
    }

    const submit = async () => {
        setLoadingSubmit(true)
        const withdraw = forms

        if(withdraw.ticket_schedule_date === "") {
            withdraw.ticket_schedule_date = (new Date()).toISOString().substring(0, 10)
        }

        try {
            const response = await createClientWithdraw(withdraw, "BOLETO")

            if(response) {
                setLoadingSubmit(false)
                window.location.reload()
            } else {
                setLoadingSubmit(false)
                message.error("Não foi possível gerar pedido de pagamento.")
            }
        } catch (error) {
            setLoadingSubmit(false)
            message.error("Não foi possível gerar pedido de pagamento.")
        }

    }

    return (
        <div className="bank-slip-payments-container">
            <div className="bank-slip-payments-content">
                <div className='bank-slip-payments-balance'>
                    <span>Saldo em conta</span>
                    <div className='bank-slip-payments-balance-button-content'>
                        <span>{visibleBalance ? (
                            Number(balance).toLocaleString("pt-br", {style: "currency", currency: "BRL"})
                        ) : (
                            "R$ ****,**"
                        )}</span>
                        <Button 
                            ghost
                            onClick={() => setVisibleBalance(!visibleBalance)}
                            icon={<img alt='' src={visibleBalance ? OpenedEye : ClosedEye}/>}
                            className="bank-slip-payments-balance-button"
                        />
                    </div>
                </div>
                <div className="bank-slip-payments">
                    <div className="bank-slip-payments-item">
                        <InputText 
                            kind="primary"
                            placeholder="Digite ou cole o código de barras"
                            name="ticket_code"
                            value={forms.ticket_code}
                            onChange={handleForms}
                            onBlur={loadBankSlipInfo}
                            suffix={
                                <Button 
                                    ghost
                                    onClick={loadBankSlipInfo}
                                    icon={<SearchIcon />}
                                    className="bank-slip-payments-balance-button"
                                />
                            }
                        />
                        {visibleBankSlipContent &&
                            <div className="bank-slip-payments-describe-content">
                                {messageBankSlipContent !== "result" ? (
                                    <div className="bank-slip-payments-balance-button-title-content">
                                        <span className="bank-slip-payments-balance-button-title">{messageBankSlipContent}</span>
                                    </div>
                                ): (
                                    <>
                                        <div className="bank-slip-payments-balance-button-title-content">
                                            <span className="bank-slip-payments-balance-button-title">Valor a pagar</span>
                                            <span className="bank-slip-payments-balance-button-value">{Number(forms.value).toLocaleString("pt-br", {style:"currency", currency:"BRL"})}</span>
                                        </div>
                                        <div className="bank-slip-payments-balance-button-summary-container">
                                            <div className="bank-slip-payments-balance-button-summary-content">
                                                <div className="bank-slip-payments-balance-button-summary-item">
                                                    <span>
                                                        <span className="bank-slip-payments-balance-button-summary-title">Vencimento: </span>
                                                        {formatDateYMD(new Date(forms.ticket_settle_date as string))}
                                                    </span>
                                                </div>
                                                <div className="bank-slip-payments-balance-button-summary-item">
                                                    <span>
                                                        <span className="bank-slip-payments-balance-button-summary-title">Beneficiário: </span>
                                                        {forms.beneficiary_name}
                                                    </span>
                                                </div>
                                                <div className="bank-slip-payments-balance-button-summary-item">
                                                    <span style={{color: "#FF0F0F"}}>
                                                        <span className="bank-slip-payments-balance-button-summary-title">Juros: </span>
                                                        {Number(forms.fee).toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                                    </span>
                                                    <span style={{color: "#FF0F0F"}}>
                                                        <span className="bank-slip-payments-balance-button-summary-title">Multa: </span>
                                                        {Number(forms.other_fee).toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                                    </span>
                                                    <span style={{color: "#00C108"}}>
                                                        <span className="bank-slip-payments-balance-button-summary-title">Desconto: </span>
                                                        {Number(forms.discount).toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        }
                    </div>
                    <div className="bank-slip-payments-forms">
                        <div>
                            <span>Pagar em</span>
                            <InputDate 
                                disabledDate
                                disabled={messageBankSlipContent !== "result"}
                                onChange={handleDate}
                            />
                        </div>
                        <div>
                            <span>Observação para pagamento</span>
                            <InputText 
                                kind="primary"
                                placeholder="Opcional"
                                disabled={messageBankSlipContent !== "result"}
                                name="observation_request"
                                value={forms.observation_request}
                                onChange={(event) => handleForms(event)}
                            />
                        </div>
                    </div>
                    <div className="bank-slip-payments-button-content">
                        <span className="bank-slip-payments-span">Pagamentos feitos após as 18h somente serão realizados no próximo dia útil.</span>
                        <CustomButton 
                            disabled={messageBankSlipContent !== "result"}
                            loading={loadingSubmit}
                            label="REALIZAR PAGAMENTO"
                            onClick={submit}
                            style={{width: "60%"}}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default connector(BankSlipPayments);