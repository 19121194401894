import { Input } from "antd";
import './styles.css'

interface InputTextInterface {
    kind: "ghost" | "primary" | "secondary";
    label?: string;
    placeholder?: string;
    value?: string;
    name?: string;
    validate?: string;
    type?: string;
    errorMessage?: string;
    disabled?: boolean;
    editabled?: boolean;
    style?: React.CSSProperties;
    suffix?: React.ReactNode;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.ChangeEventHandler<HTMLInputElement>;
}

const InputText = ({
    kind,
    label,
    placeholder,
    name,
    value,
    validate,
    errorMessage,
    disabled,
    style,
    type,
    suffix,
    onChange,
    onBlur
}:InputTextInterface) => {
    return (
        <>
            <div style={style} className="container-input">
                {label && 
                    <label className="input-title">{label}</label>
                }
                <Input 
                    type={type}
                    placeholder={placeholder} 
                    name={name} 
                    value={value}
                    className={`input input-${kind} ${validate}`}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    suffix={suffix}
                />
                {(validate==='error' || validate==='success') &&
                    <div className={`helper-text ${validate}`}>
                        <span style={validate === "error" ? {color: "#FF0000"} : {color: "#00C108"} }>{errorMessage}</span>
                    </div>
                }
            </div>
        </>
    )
}

export default InputText;