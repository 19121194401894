import './App.css';

import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';

import { Roles } from './types/roles.enum';
import { UserInterface } from './types/user.type';
import { alertActions } from './actions/alert.actions';
import { userActions } from './actions/user';
import { authActions } from './actions/auth';

import PrivateRoute from './components/Routes/PrivateRoute';

import Authentication from './pages/Authentication';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Application from './pages/Application';

import Home from './pages/Application/Home/Home';

import Profile from './pages/Application/Profile';
import MyProfile from './pages/Application/Profile/Me';
import AdditionalProfiles from './pages/Application/Profile/Additional';

import Clients from './pages/Application/Clients';
import ClientInformation from './pages/Application/Clients/Information';
import ClientForms from './pages/Application/Clients/Forms';

import BalanceHistoryPage from './pages/Application/BalanceHistory';
import TransactionsPage from './pages/Application/Transactions';
import Payments from './pages/Application/Payments';
import Pix from './pages/Application/Pix';
import TaxSimulator from './pages/Application/TaxSimulator';
import TaxGroups from './pages/Application/TaxGroups';
import PaymentVoucher from './pages/Application/PaymentVoucher';

//import Transfers from './pages/Application/Transfers';

interface AppState {
  authentication: {
    loggedIn: boolean;
  };
  user: {
    info: UserInterface;
  }
}

const mapState = (state: AppState) => ({
  loggedIn: state.authentication.loggedIn,
  user: state.user.info,
});

const actionCreators = {
  loadInformation: userActions.loadInformation,
  clearAlerts: alertActions.clear,  
  logout: authActions.logout,
};

const connectedApp = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connectedApp>;

const App = ({ 
  loggedIn, 
  user, 
  logout,
  loadInformation
}: PropsFromRedux) => {
  
  useEffect(() => { if(loggedIn) loadInformation() }, [loggedIn])

  const storage = localStorage.getItem("userDetails")
  const userDetails:UserInterface = storage && JSON.parse(storage)

  const renderManagerProfiles = () => {

    if (userDetails && userDetails.permissions?.control_limits) {
      return (
        <>
          <Route path="/perfil" element={<Profile />}/>
          <Route path="/perfil/me" element={ <MyProfile /> } />
          <Route path="/perfil/adicional/:method" element={<AdditionalProfiles />} />
        </>
      )
    }

    return (
      <>
        <Route path="/perfil/me" element={ <MyProfile /> } />
        <Route path="/perfil/adicional/:method" element={<AdditionalProfiles />} />
      </>
    )

  }

  const renderManagerClients = () => {
    if (userDetails && userDetails.role === "admin") {
      return (
        <>
          <Route path="/clientes" element={<Clients />} />
          <Route path="/clientes/novo" element={<ClientForms />} />
          <Route path="/clientes/:id" element={<ClientInformation />} />
        </>
      )
    }
  }

  const renderManagerBalanceHistory = () => {
    if (userDetails && (userDetails.role === "user" && userDetails.permissions?.view_balance)) {
      return (
        <Route path="/saldo" element={<BalanceHistoryPage />} />
      )
    }
  }

  const renderManagerTransactions = () => {
    if (userDetails && (userDetails.role === "user" && userDetails.permissions?.view_transactions)) {
      return (
        <Route path="/vendas" element={<TransactionsPage />} />
      )
    }
  }

  const renderManagerPix = () => {
    if (user.role !== "admin") {
      if (userDetails && userDetails.limits) {
        const limits = userDetails.limits.filter(item => item.operation_type_id === 8)
        if (limits.length > 0) return <Route path="/pix" element={<Pix />} />
      }
    } else {
      return <Route path="/pix" element={<Pix />} />
    }
  }

  const renderManagerPayments = () => {
    if (user.role !== "admin") {
      if (userDetails && userDetails.limits) {
        const limits = userDetails.limits.filter(item => item.operation_type_id === 6)
        if (limits.length > 0) return <Route path="/pagamentos" element={<Payments />} />
      }
    } else {
      return <Route path="/pagamentos" element={<Payments />} />
    }
  }

  const renderManagerSimulator = () => {
    if (user.role !== "admin") {
      return <Route path="/simulacao" element={<TaxSimulator />} />
    }
    return
  }

  const renderManagerPaymentVoucher = () => {
    if (user.role !== "admin") {
      if (userDetails && userDetails.limits) {
        const limits = userDetails.limits?.map(item => item.operation_type_id)
        if (limits.includes(8) || limits.includes(6)) return <Route path="/comprovantes" element={<PaymentVoucher />} />
      }
    }
    return
  }

  const renderManagerTaxGroup = () => {
    if (user.role === "admin") return <Route path="/grupo-taxas" element={<TaxGroups />} />
  }
  
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute isAuthenticated={loggedIn} />}>
        <Route path="/" element={user != null ? <Application logout={logout} role={user.role as Roles} /> : <></>}>
          <Route path="/" element={<Home />} />
                    
          {[
            renderManagerPix(),
            renderManagerClients(),
            renderManagerPayments(),
            renderManagerProfiles(),
            renderManagerTaxGroup(),
            renderManagerSimulator(),
            renderManagerTransactions(),
            renderManagerBalanceHistory(),
            renderManagerPaymentVoucher()
          ]}

        </Route>
      </Route>
      <Route path="/auth" element={!loggedIn ? <Authentication /> : <Navigate to="/" />} />
      <Route path="/reset-password/:id" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default connectedApp(App);
