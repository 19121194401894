import { Col, Row } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { formatDate } from '../../helpers/utils';
import { BalanceHistoryInterface, BalanceHistoryInterfacePagination } from '../../types/balance_history.type';

import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';
import {ReactComponent as CalendarIcon} from '../../static/icon/calendar-green.svg';
import {ReactComponent as TransactionTypeIcon} from '../../static/icon/transaction-type.svg';
import {ReactComponent as MoneyIcon} from '../../static/icon/money-green.svg';

import './styles.css'
import PixReceiptButton from '../ReceiptButton';

interface BalanceHistoryTableProps {
  top?: number;
  minimal?: boolean;
  select?: string[];
  active_scroll?: boolean
  balance_history: BalanceHistoryInterface[];
  showBalance?: boolean;
  pagination?: BalanceHistoryInterfacePagination;
  changePage?: (page: number) => void;
}

const BalanceHistoryTable = ({ 
  top,
  minimal,
  select,
  active_scroll,
  balance_history, 
  showBalance,
  pagination,
  changePage
}: BalanceHistoryTableProps) => {
  const columns: ColumnsType<BalanceHistoryInterface> = [
    {//date
      key: 'date',
      dataIndex: 'date',
      render: (record: number) => {
        const date = new Date(record * 1000);
        return (
          <Col className={`balance-table-cell ${minimal && 'first-cell'}`}>
              <Row align='middle'>
                  <CalendarIcon />
                  <span className='balance-table-title'>Data do pedido</span>
              </Row>
              <Row>
                  <span className='balance-table-value'>{formatDate(date)}</span>
              </Row>
          </Col>
        )
      },
    },
    {//operation_type
      key: 'operation_type',
      dataIndex: 'operation_type',
      render: (record:string) => {
        return (
            <Col className='balance-table-cell'>
                <Row align='middle'>
                    <TransactionTypeIcon />
                    <span className='balance-table-title'>Transação</span>
                </Row>
                <Row>
                    <span className='balance-table-value'>{record}</span>
                </Row>
            </Col>
        )
      }
    },
    {//transaction_total_value
      key: 'transaction_total_value',
      dataIndex: 'transaction_total_value',
      render: (record:string) => {
        return (
          <Col className='balance-table-cell'>
              <Row align='middle'>
                  <MoneyIcon />
                  <span className='balance-table-title'>Valor da transação</span>
              </Row>
              <Row>
                  <span className='balance-table-value'>{Number(record).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</span>
              </Row>
          </Col>
        )
      }
    },
    {//value
      key: 'value',
      dataIndex: 'value',
      render: (record:string) => {
        return (
          <Col className={`balance-table-cell ${!showBalance && 'last-cell'}`}>
              <Row align='middle'>
                  <MoneyIcon />
                  <span className='balance-table-title'>Valor líquido</span>
              </Row>
              <Row>
                  <span className='balance-table-value' style={{color: Number(record) > 0 ? "#00C108" : "#FF0000"}}>{Number(record).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</span>
              </Row>
          </Col>
        )
      }
    },
    {//final_value
      key: 'final_value',
      dataIndex: 'final_value',
      render: (record:string) => {
        return (
          <Col className='balance-table-cell last-cell'>
              <Row align='middle'>
                  <MoneyIcon />
                  <span className='balance-table-title'>Saldo atual</span>
              </Row>
              <Row>
                  <span className='balance-table-value' style={{color: Number(record) > 0 ? "#00C108" : "#FF0000"}}>{Number(record).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</span>
              </Row>
          </Col>
        )
      }
    },
  ];

  const handeShowColumns = () => {
    if (select) {
      return columns.filter(item => {
        if (item.key && select.includes(String(item.key))) return item
      })
    } else if (!showBalance) {
      return columns.filter(item => item.key !== 'final_value')
    } else {
      return columns
    }
  }

  return (
    <div>
      <Table
        rowKey='id'
        size="middle"
        className="balance-table"
        showHeader={false}
        columns={handeShowColumns()} 
        scroll={active_scroll ? { x: 1000, y: 600 } : undefined}
        dataSource={top ? balance_history.slice(0, top) : balance_history}
        expandable={minimal ? undefined : {
          expandIcon: ({expanded, onExpand, record}) => {
              return (
                  <Col className='pix-table-cell first-cell' style={{justifyContent: "center"}}>
                      {expanded ? (
                          <MinusSquareOutlined style={{ fontSize: 20, color: "#0A1E41" }} onClick={e => onExpand(record, e)}/>
                      ) : (
                          <PlusSquareOutlined  style={{ fontSize: 20, color: "#0A1E41" }} onClick={e => onExpand(record, e)} />
                      )}
                  </Col>
              )
          },
          expandedRowRender: (record) => {
              return (
                <Col xs={24} sm={24} xl={24}>
                  <Row className='balance-table-description'>
                      <Col xs={8} sm={8} xl={8} className="balance-table-status">
                        {record.operation_type.toLocaleLowerCase().includes('recusad') ? (
                          <span className='balance-table-description-value'>Status do pagamento: <span className='value' style={{color: "#FF0000"}}>Recusado</span></span>
                        ) : (
                          <span className='balance-table-description-value'>Status do pagamento: <span className='value' style={{color: "#00C108"}}>Aprovado</span></span>
                        )}
                      </Col>
                      {record.payer_name &&
                        <Col xs={8} sm={8} xl={8} className="balance-table-status">
                          <span className='balance-table-description-value'>Pagador: <span style={{color: "white"}}>{record.payer_name}</span></span>
                        </Col>
                      }
                      {record.withdrawal_id ? (
                        <Col xs={8} sm={8} xl={8} className="balance-table-actions">
                          <Row align='middle'>
                            <PixReceiptButton  withdraw_id={record.withdrawal_id as number} />
                          </Row>
                        </Col>
                      ) : (
                        <span style={{color: "white"}}>Não possui comprovante</span>
                      )}
                  </Row>
                </Col>
              )
          } 
        }}
        pagination={!changePage ? false : {
          position: ['bottomCenter'],
          total: pagination ? (pagination.pages * pagination.per_page * 2) : 0,
          showSizeChanger: false,
          onChange(page) {
            changePage(page)
          },
        }}
      />
    </div>
  );
};

export default BalanceHistoryTable;
